import LoadingSpinner from 'LoadingSpinner';
import LogRocket from 'logrocket';
import Mixpanel from 'mixpanel-browser';
import { IntercomProvider } from 'react-use-intercom';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Suspense, lazy, useCallback, useEffect, useRef } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { getUrlParams } from 'utils/smartPersonas/urlParameters';
import { initialize } from 'react-ga';
import { paths } from './constants/paths';
import { useAuth0 } from '@auth0/auth0-react';
import { useGlobalState } from 'state'; // Assuming you have a LoadingSpinner component
import * as Sentry from '@sentry/react';
import * as Styled from './App.styles';

// Lazy load components
const GlobalHeader = lazy(() => import('components/GlobalHeader/GlobalHeader'));
const InternalApp = lazy(() => import('./InternalApp'));
const Login = lazy(() => import('views/Login'));
const LoginRedirect = lazy(() => import('views/LoginRedirect'));
const Onboarding = lazy(() => import('views/Onboarding'));
const Signup = lazy(() => import('views/Signup'));
const SignupDirect = lazy(() => import('views/SignupDirect'));
const SignupV2 = lazy(() => import('views/SignupV2'));
const SignupV3 = lazy(() => import('views/SignupV3'));
const SmartPersona = lazy(
  () => import('components/SmartPersona/SmartPersonaInsights')
);
const SmartPersonaAudience = lazy(
  () => import('components/SmartPersona/SmartPersonaAudience')
);
const SmartPersonaMeasurement = lazy(
  () => import('components/SmartPersona/SmartPersonaMeasurement')
);
const VerifyEmail = lazy(() => import('views/VerifyEmail'));
const NotFound = lazy(() => import('views/404'));
const Forbidden = lazy(() => import('views/403'));
const NavBar = lazy(() => import('components/NavBar'));

const App = () => {
  const [state, actions] = useGlobalState();
  const { isLoading, error, user, isAuthenticated } = useAuth0();
  const location = useLocation();
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const positions = useRef<Map<string, { x: number; y: number }>>(new Map());

  // Initialize Google Analytics
  useEffect(() => {
    if (import.meta.env.VITE_PROD) {
      initialize('G-X7XW5XXEJB');
    }
  }, []);

  // Initialize third-party services
  useEffect(() => {
    const {
      VITE_LOG_ROCKET_APP_ID: LOG_ROCKET_APP_ID,
      VITE_MIXPANEL_TOKEN,
      VITE_DATADOG_APPLICATION_ID,
      VITE_DATADOG_CLIENT_TOKEN,
      VITE_DATADOG_ENVIRONMENT,
    } = import.meta.env;

    if (LOG_ROCKET_APP_ID) {
      LogRocket.init(LOG_ROCKET_APP_ID, {
        network: {
          requestSanitizer: (request) => {
            request.headers['authorization'] = '';
            request.headers['Auth0-Client'] = '';
            return request;
          },
        },
        shouldDebugLog: !import.meta.env.VITE_PROD,
      });
    }

    if (VITE_MIXPANEL_TOKEN) {
      Mixpanel.init(VITE_MIXPANEL_TOKEN);
    }

    if (
      VITE_DATADOG_APPLICATION_ID &&
      VITE_DATADOG_CLIENT_TOKEN &&
      VITE_DATADOG_ENVIRONMENT &&
      !datadogRum.getInitConfiguration()
    ) {
      datadogRum.init({
        applicationId: VITE_DATADOG_APPLICATION_ID,
        clientToken: VITE_DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'ksaas-web',
        env: VITE_DATADOG_ENVIRONMENT,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
      datadogRum.startSessionReplayRecording();
    }

    const urlParams = getUrlParams();
    if (urlParams?.personas && urlParams.personaType) {
      actions.setSmartPersonaId(urlParams.personas, urlParams.personaType);
    }
  }, [actions]);

  // Identify user in third-party services
  useEffect(() => {
    if (user?.sub) {
      LogRocket.identify(user.sub, {
        name: user.name ?? '',
        email: user.email ?? '',
      });

      window.analytics.identify(user.sub, {
        name: user.name ?? '',
        email: user.email ?? '',
      });
    }
  }, [user]);

  // Custom scroll management
  useEffect(() => {
    if (positions.current.has(location.key)) {
      const { x, y } = positions.current.get(location.key)!;
      window.scrollTo(x, y);
    } else {
      window.scrollTo(0, 0);
    }

    const handler = () => {
      positions.current.set(location.key, {
        x: window.scrollX,
        y: window.scrollY,
      });
    };

    window.addEventListener('scroll', handler);

    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, [location]);

  const scrollDrawerToTop = useCallback(() => {
    if (drawerRef.current?.children[2]) {
      (drawerRef.current.children[2] as HTMLDivElement).scrollTo({ top: 0 });
    }
  }, []);

  const renderDrawerContent = () => {
    if (state.drawerContents) {
      return state.drawerContents;
    }

    switch (state.smartPersonaType) {
      case 'insights':
        return (
          <SmartPersona
            id={state.smartPersonaId}
            scrollDrawerToTop={scrollDrawerToTop}
          />
        );
      case 'audience':
        return (
          <SmartPersonaAudience
            id={state.smartPersonaId}
            scrollDrawerToTop={scrollDrawerToTop}
          />
        );
      case 'measurement':
        return (
          <SmartPersonaMeasurement
            personaInput={state.smartPersonasInputData}
            scrollDrawerToTop={scrollDrawerToTop}
          />
        );
      default:
        return null;
    }
  };

  if (isLoading) return null; // or <LoadingSpinner />

  if (error) return <div>Oops... {error.message}</div>;

  const isMap = location.pathname === paths.MAP;

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  // Array of signup paths
  const signupPaths = [
    paths.SIGNUP,
    paths.SIGNUP_V1,
    paths.SIGNUP_COMPETITOR,
    paths.SIGNUP_DEMOGRAPHICS,
    paths.SIGNUP_MARKET_RESEARCH,
    paths.SIGNUP_MARKET_SHARE,
    paths.SIGNUP_CONQUESTING,
  ];

  return (
    <Styled.Wrapper>
      <Suspense fallback={<LoadingSpinner />}>
        <IntercomProvider appId="ssmf88uq">
          <SentryRoutes>
            {/* Public Routes */}
            <Route path="/" element={<Navigate to={paths.HOME} replace />} />
            <Route path={paths.LOGIN} element={<Login />} />

            {/* Signup Routes */}
            {signupPaths.map((path) => (
              <Route key={path} path={path} element={<Signup />} />
            ))}
            <Route path={paths.SIGNUP_V2} element={<SignupV2 />} />
            <Route path={paths.SIGNUP_V3} element={<SignupV3 />} />
            <Route path={paths.SIGNUP_DIRECT} element={<SignupDirect />} />
            <Route path={paths.ONBOARDING} element={<Onboarding />} />

            {/* Other Public Routes */}
            <Route path={paths.VERIFYEMAIL} element={<VerifyEmail />} />
            <Route path={paths.LOGIN_REDIRECT} element={<LoginRedirect />} />
            <Route path="/403" element={<Forbidden />} />

            {/* Protected Routes */}
            <Route
              path="/*"
              element={
                isAuthenticated ? (
                  isMap ? (
                    <InternalApp />
                  ) : (
                    <Styled.GlobalGrid>
                      <Styled.Header>
                        <GlobalHeader />
                      </Styled.Header>
                      <Styled.Nav>
                        <NavBar />
                      </Styled.Nav>
                      <Styled.ContentNew>
                        <InternalApp />
                      </Styled.ContentNew>
                    </Styled.GlobalGrid>
                  )
                ) : (
                  <Navigate to={paths.LOGIN} replace />
                )
              }
            />

            {/* Catch-all Route */}
            <Route path="*" element={<NotFound />} />
          </SentryRoutes>
        </IntercomProvider>
      </Suspense>

      <Styled.DrawerWrapper
        ref={drawerRef}
        anchor="right"
        open={state.showDrawer}
        onClose={actions.hideDrawer}
      >
        <Suspense fallback={<LoadingSpinner />}>
          {renderDrawerContent()}
        </Suspense>
      </Styled.DrawerWrapper>

      {state.showToast && (
        <Styled.Toast
          data-test-id="global-toast"
          autoHideDuration={5000}
          open={state.showToast}
          error={state.toastError}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          onClose={actions.hideToast}
          message={
            <>
              {state.toastHeader && (
                <Styled.ToastHeader>{state.toastHeader}</Styled.ToastHeader>
              )}
              {state.toastMsg}
            </>
          }
        />
      )}
    </Styled.Wrapper>
  );
};

export default App;
